html,
body {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0;
    border: 0;
    padding: 0;

    &html {
        overflow: auto;
    }
}

ul {
    margin-block-start: 0.2em;
    margin-block-end: 2em;
    line-height: 1.5;
}

oag-figure {

    .images-half {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 1em;
    }

    .images-half img {
        width: 50%;
    }

    table.inschrift {
        line-height: 1;
        width: 100%;
        border: 0;
        border-collapse: collapse;

        th,
        td {
            padding: 1em;
            width: 50%;
            text-align: left;
        }

        thead tr {
            background-color: #ddd;
        }

        tbody {
            tr {
                &:nth-child(odd) {
                    background-color: #f8f8f8;
                }

                &:nth-child(even) {
                    background-color: #f4f4f4;
                }
            }
        }
    }
}


.figure-with-3d-model {
    padding: 0;
    margin-top: var(--default-layout-padding);

    iframe {
        width: 100%;
        height: 500px;
        max-height: 90vh;
    }
}

.container-3d-models {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: var(--default-layout-padding);

    oag-figure {
        width: 50%;
        padding: 0;

        iframe {
            width: 100%;
            height: 500px;
            max-height: 90vh;
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;

        oag-figure {
            width: 100%;
        }
    }
}

oag-imprint {
    border-top: 1px solid #f1f1f1;
    padding-top: var(--default-layout-padding);
}

oag-map {
    background-color: #f4f4f4;
}

// Menu
oag-menu:not(.scrolled) .open-cockpit {
    --menu-item-background-color: #fff;
    --menu-item-background-color-hover: #eee;
    --menu-item-background-color-active: #ddd;
    --menu-item-text-color: #333;
    --menu-item-text-color-hover: #111;
    --menu-item-text-color-active: #000;
}

// Cockpit
oag-cockpit {
    oag-cockpit-navigation-item {

        &.level-1 {
            --cockpit-navigation-item-size: 0.9rem;
            --cockpit-navigation-item-margin-top: 2em;
            --cockpit-navigation-item-text-color: #999;
            text-transform: uppercase;
            font-weight: 500;
        }

        &.level-2 {
            --cockpit-navigation-item-size: 1.35em;
        }
    }

    h1[slot="head"] {
        margin: 0;
    }

    nav[slot=navigation] {
        display: flex;
        flex-direction: row;
        gap: 2em;

        .navigation-column {
            width: 50%;
        }
    }

    @media screen and (max-width: 768px) {

        nav[slot=navigation] {
            flex-wrap: wrap;

            .navigation-column {
                width: 100%;
            }
        }
    }
}

// Screen Map
oag-screen-map {
    --screen-map-background-color: #eee;

    .screen-headline {
        margin-top: 2em;
        margin-bottom: 1em;
    }

    .screen-headline:first-child {
        margin-top: 0;
    }

    .screen-headline h2 {
        margin: 0;
        padding: 0;
    }

    .screen-headline h3 {
        margin: 0;
        padding: 0;
        font-size: 1.25em;
        color: #555;
    }
}