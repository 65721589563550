html, body {
  border: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: block;
}

htmlhtml, bodyhtml {
  overflow: auto;
}

ul {
  margin-block: .2em 2em;
  line-height: 1.5;
}

oag-figure .images-half {
  flex-direction: row;
  gap: 1em;
  width: 100%;
  display: flex;
}

oag-figure .images-half img {
  width: 50%;
}

oag-figure table.inschrift {
  border-collapse: collapse;
  border: 0;
  width: 100%;
  line-height: 1;
}

oag-figure table.inschrift th, oag-figure table.inschrift td {
  text-align: left;
  width: 50%;
  padding: 1em;
}

oag-figure table.inschrift thead tr {
  background-color: #ddd;
}

oag-figure table.inschrift tbody tr:nth-child(odd) {
  background-color: #f8f8f8;
}

oag-figure table.inschrift tbody tr:nth-child(2n) {
  background-color: #f4f4f4;
}

.figure-with-3d-model {
  margin-top: var(--default-layout-padding);
  padding: 0;
}

.figure-with-3d-model iframe {
  width: 100%;
  height: 500px;
  max-height: 90vh;
}

.container-3d-models {
  gap: var(--default-layout-padding);
  flex-direction: row;
  width: 100%;
  display: flex;
}

.container-3d-models oag-figure {
  width: 50%;
  padding: 0;
}

.container-3d-models oag-figure iframe {
  width: 100%;
  height: 500px;
  max-height: 90vh;
}

@media screen and (width <= 768px) {
  .container-3d-models {
    flex-direction: column;
  }

  .container-3d-models oag-figure {
    width: 100%;
  }
}

oag-imprint {
  padding-top: var(--default-layout-padding);
  border-top: 1px solid #f1f1f1;
}

oag-map {
  background-color: #f4f4f4;
}

oag-menu:not(.scrolled) .open-cockpit {
  --menu-item-background-color: #fff;
  --menu-item-background-color-hover: #eee;
  --menu-item-background-color-active: #ddd;
  --menu-item-text-color: #333;
  --menu-item-text-color-hover: #111;
  --menu-item-text-color-active: #000;
}

oag-cockpit oag-cockpit-navigation-item.level-1 {
  --cockpit-navigation-item-size: .9rem;
  --cockpit-navigation-item-margin-top: 2em;
  --cockpit-navigation-item-text-color: #999;
  text-transform: uppercase;
  font-weight: 500;
}

oag-cockpit oag-cockpit-navigation-item.level-2 {
  --cockpit-navigation-item-size: 1.35em;
}

oag-cockpit h1[slot="head"] {
  margin: 0;
}

oag-cockpit nav[slot="navigation"] {
  flex-direction: row;
  gap: 2em;
  display: flex;
}

oag-cockpit nav[slot="navigation"] .navigation-column {
  width: 50%;
}

@media screen and (width <= 768px) {
  oag-cockpit nav[slot="navigation"] {
    flex-wrap: wrap;
  }

  oag-cockpit nav[slot="navigation"] .navigation-column {
    width: 100%;
  }
}

oag-screen-map {
  --screen-map-background-color: #eee;
}

oag-screen-map .screen-headline {
  margin-top: 2em;
  margin-bottom: 1em;
}

oag-screen-map .screen-headline:first-child {
  margin-top: 0;
}

oag-screen-map .screen-headline h2 {
  margin: 0;
  padding: 0;
}

oag-screen-map .screen-headline h3 {
  color: #555;
  margin: 0;
  padding: 0;
  font-size: 1.25em;
}
/*# sourceMappingURL=index.50d60476.css.map */
